import type Splide from '@splidejs/splide';
import { defineModule } from '@/js/utils/helpers';

const splides: Splide[] = [];

const getElements = () => ({
  referencesSplideElements: document.querySelectorAll<HTMLElement>(
    '.content-block--references .splide',
  ),
});

export default defineModule(
  async () => {
    const { referencesSplideElements } = getElements();
    if (!referencesSplideElements.length) return;

    const { Splide } = await import('@splidejs/splide');

    referencesSplideElements.forEach((referencesSplideElement) => {
      const splide = new Splide(referencesSplideElement, {
        type: 'fade',
        arrows: true,
        rewind: true,
        drag: true,
        clones: 0,
        focus: 'center',
        autoplay: true,
        pagination: true,
        speed: 600,
        interval: 6000,
        }).mount();

      splides.push(splide);
    });
  },
  () => {
    while (splides.length > 0) {
      splides.pop()?.destroy(true);
    }
  },
);
