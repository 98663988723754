import type { Map } from 'leaflet';
import markerSvg from '@/images/marker.svg';

import { defineModule } from '../../utils/helpers';

let overviewMap: Map;

const getElements = () => ({
  mapElement: document.querySelector<HTMLElement>('#footer--map'),
});

const getTileTemplateUrl = (provider: string) => {
  if (provider === 'Stadia.AlidadeSmooth') {
    return 'https://tiles.stadiamaps.com/tiles/alidade_smooth';
  }
  return 'https://{s}.tile.openstreetmap.org';
};

export default defineModule(
  async () => {
    const { mapElement } = getElements();
    if (!mapElement) {
      return;
    }

    const { LatLng, Map, TileLayer, DivIcon, Marker } = await import('leaflet');

    const mapLat = parseFloat(mapElement.dataset.lat ?? '0');
    const mapLng = parseFloat(mapElement.dataset.lng ?? '0');
    const zoom = parseInt(mapElement.dataset.zoom ?? '0', 10);

    overviewMap = new Map(mapElement, {
      center: new LatLng(mapLat, mapLng),
      zoom,
      scrollWheelZoom: false,
      dragging: false,
      doubleClickZoom: false,
      zoomControl: false,
    });

    new TileLayer(
      `${getTileTemplateUrl(
        import.meta.env.VITE_LEAFLET_PROVIDER,
      )}/{z}/{x}/{y}.png`,
      {
        attribution: [
          '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a>',
          '&copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a>',
          '&copy; <a href="https://www.openstreetmap.org/about/" target="_blank">OpenStreetMap contributors</a>',
        ].join(' '),
        maxZoom: 16,
      },
    ).addTo(overviewMap);

    new Marker(
      [
        parseFloat(mapElement.dataset.lat ?? '0'),
        parseFloat(mapElement.dataset.lng ?? '0'),
      ],
      {
        icon: new DivIcon({
          className: 'bg-transparent',
          iconSize: [43, 48],
          iconAnchor: [21, 48],
          html: markerSvg,
        }),
        interactive: false,
      },
    ).addTo(overviewMap);
  },

  () => {
    overviewMap.remove();
  },
);
