import { defineModule } from '../../utils/helpers';

const getElements = () => ({
  headerElement: document.querySelector('.content-block--header'),
});

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        document.body.classList.remove('page--menu-scroll');
        return;
      }

      document.body.classList.add('page--menu-scroll');
    });
  },
  {
    rootMargin: '0px',
    threshold: 0,
  },
);

export default defineModule(
  () => {
    const { headerElement } = getElements();
    if (!headerElement) return;

    observer.observe(headerElement);
  },
  () => {
    observer.disconnect();
  },
);
