import { defineModule } from '../utils/helpers';

const getElements = () => ({
  scrollToNextBlockElements: document.querySelectorAll<HTMLElement>(
    '[data-scroll-to-next-block]',
  ),
});

const onScrollToNextBlockClick = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  e.currentTarget
    .closest<HTMLElement>('.content-block')
    ?.nextElementSibling?.scrollIntoView({ behavior: 'smooth' });
};

export default defineModule(
  () => {
    const { scrollToNextBlockElements } = getElements();

    scrollToNextBlockElements.forEach((scrollToNextBlockElement) => {
      scrollToNextBlockElement.addEventListener(
        'click',
        onScrollToNextBlockClick,
      );
    });
  },
  () => {
    const { scrollToNextBlockElements } = getElements();

    scrollToNextBlockElements.forEach((scrollToNextBlockElement) => {
      scrollToNextBlockElement.removeEventListener(
        'click',
        onScrollToNextBlockClick,
      );
    });
  },
);
