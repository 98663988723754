import { defineModule } from '../../utils/helpers';

const getElements = () => ({
  frmFormFieldElements: document.querySelectorAll(
    '.frm_form_field.frm_none_container',
  ),
});

export default defineModule(() => {
  const { frmFormFieldElements } = getElements();

  frmFormFieldElements.forEach((frmFormFieldElement) => {
    const labelElement =
      frmFormFieldElement.querySelector<HTMLLabelElement>('label');
    if (!labelElement || !labelElement.textContent?.length) return;

    const formControlElement = frmFormFieldElement.querySelector<
      HTMLInputElement | HTMLTextAreaElement
    >('input, textarea');
    if (!formControlElement || formControlElement.placeholder.length) return;

    formControlElement.placeholder = labelElement.textContent.replace(
      /\s+/g,
      ' ',
    );
  });
});
