import { defineModule } from '../../utils/helpers';
import { Overlay, toggleOverlay } from '../../utils/overlays';

const getElements = () => ({
  navbarTogglerElement: document.querySelector('.navbar .navbar__toggler'),
  navbarDrawerElement: document.querySelector('.navbar .navbar__drawer'),
});

export const toggleNavbar = (force?: boolean) => {
  const { navbarTogglerElement, navbarDrawerElement } = getElements();
  if (!navbarTogglerElement || !navbarDrawerElement) return;

  const isCurrentlyOpen = document.body.classList.contains('page--menu-open');
  force = force ?? !isCurrentlyOpen;

  const isOpen = document.body.classList.toggle('page--menu-open', force);
  toggleOverlay(Overlay.MENU, isOpen);

  navbarTogglerElement.ariaExpanded = `${isOpen}`;
  navbarDrawerElement.ariaHidden = `${!isOpen}`;
};

const onNavbarTogglerClick = () => toggleNavbar();

export default defineModule(
  () => {
    const { navbarTogglerElement } = getElements();
    if (!navbarTogglerElement) return;

    navbarTogglerElement.addEventListener('click', onNavbarTogglerClick);
  },
  () => {
    const { navbarTogglerElement } = getElements();
    if (!navbarTogglerElement) return;

    navbarTogglerElement.removeEventListener('click', onNavbarTogglerClick);
    toggleNavbar(false);
  },
);
